import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
declare var $: any;
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user';
import { ApicallsService } from '../../services/apicalls.service';
import * as global from '../../services/global.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  user:any;

  constructor(private api: ApicallsService, private auth: AuthenticationService) {}

  
  ngOnInit(){

    this.user = this.auth.currentUserValue;
    
  }
  
 

  logout() {
    this.auth.logout();
  }
}
