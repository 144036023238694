<!-- This example requires Tailwind CSS v2.0+ -->
<div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img class="h-8 w-8" src="assets/images/icon.png" alt="Workflow">
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <a href="/startups" class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Startups</a>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <div class="ml-3 relative">
                <div>
                  <a (click)="logout()" class="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium cursor-pointer">Salir</a>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button type="button" (click)="openMenu()" class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!--
                Heroicon name: outline/menu
  
                Menu open: "hidden", Menu closed: "block"
              -->
              <svg class="h-6 w-6" *ngIf="!menu_open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!--
                Heroicon name: outline/x
  
                Menu open: "block", Menu closed: "hidden"
              -->
              <svg class="h-6 w-6" *ngIf="menu_open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
  
      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="md:hidden" id="mobile-menu" *ngIf="menu_open">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a href="/home" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Startups</a>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="mt-3 px-2 space-y-1">
            <a (click)="logout()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">Salir</a>
          </div>
        </div>
      </div>
    </nav>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" *ngIf="modalImportar">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" (click)="cerrarModalImportar()"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form (submit)="importar(importForm.value)" [formGroup]="importForm">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation -->
                <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Importar startup
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                  A continuación encontrarás un listado de startups que han solicitado el PASS y podrás integrarla manualmente
                  </p>
                  
                    <div class="shadow overflow-hidden sm:rounded-md">
                      <div class="col-span-6 sm:col-span-2">
                        <select id="startup" formControlName="startup" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Selecciona una opción</option>
                          <option *ngFor="let item of startups_import | keyvalue" value="{{item.key}}">{{item.value}}</option>
                        </select>
                      </div>
                    </div>
                  
                </div>
              </div>
            </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="submit" [disabled]="importForm.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Importar
              </button>
              <button type="button" (click)="cerrarModalImportar()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm mr-2">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" *ngIf="modalFilter">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" (click)="cerrarModalFilter()"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form (submit)="filtrar(filterForm.value)" [formGroup]="filterForm">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="#2e7d32">
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Filtrar Startups
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                  Selecciona los filtros que deseas aplicar al listado y haz click en el botón "Aplicar Filtros"
                  </p>
                  
                    <div class="overflow-hidden sm:rounded-md">
                      <div class="col-span-6 sm:col-span-2">
                        <select id="startup" formControlName="filter_ano" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Año - Todos</option>
                          <option value="2021">2021</option>
                        </select>
                        <select id="startup" formControlName="filter_sol_inicial" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Solicitud inicial - Todo</option>
                          <option value="aprobada">Aprobada</option>
                          <option value="por_evaluar">Por evaluar</option>
                          <option value="aplazada">Aplazada</option>
                          <option value="denegada">Denegada</option>
                        </select>
                        <select id="startup" formControlName="filter_entrevista" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Entrevista - Todo</option>
                          <option value="no_procede">No procede</option>
                          <option value="pendiente">Por concertar</option>
                          <option value="agendada">Agendada</option>
                          <option value="celebrada">Celebrada</option>
                          <option value="aplazada">Aplazada</option>
                          <option value="denegada">Denegada</option>
                        </select>
                        <select id="startup" formControlName="filter_form_tecnico" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Formulario técnico - Todo</option>
                          <option value="no_procede">No procede</option>
                          <option value="por_enviar">Por enviar enlace</option>
                          <option value="por_cumplimentar">Por cumplimentar</option>
                          <option value="cumplimentado">Cumplimentado</option>
                        </select>
                        <select id="startup" formControlName="filter_ex_ante" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Informe ex ante - Todo</option>
                          <option value="no_procede">No procede</option>
                          <option value="por_realizar">Por realizar</option>
                          <option value="realizado">Realizado</option>
                          <option value="favorable">Favorable</option>
                          <option value="desfavorable">Desfavorable</option>
                          <option value="aplazada">Aplazada</option>
                        </select>
                        <select id="startup" formControlName="filter_comite" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Comité de evaluación - Todo</option>
                          <option value="no_procede">No procede</option>
                          <option value="por_evaluar">Por evaluar</option>
                          <option value="evaluada">Evaluada</option>
                        </select>
                        <select id="startup" formControlName="filter_otorgado" class="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                          <option value="">Otorgado - Todo</option>
                          <option value="no_procede">No procede</option>
                          <option value="si">Si</option>
                          <option value="no">No</option>
                          <option value="aplazada">Aplazada</option>
                        </select>
                      </div>
                    </div>
                  
                </div>
              </div>
            </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Aplicar filtros
              </button>
              <button type="button" (click)="cerrarModalFilter()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm mr-2">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <header class="bg-white shadow" *ngIf="!idStartup">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
        <h1 class="text-3xl font-bold text-gray-900">
          Listado de Startups
        </h1>
       <div>
        <a class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-2 rounded inline-flex items-center cursor-pointer" (click)="openModalImportar()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
            <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
          </svg>
          <span>Importar startup</span>
        </a>
        <a class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center cursor-pointer" (click)="openModalFilter()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
            <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
          </svg>
          <span>Filtrar</span>
        </a>
       </div>
      </div>
    </header>
    <main *ngIf="!idStartup">
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <div class="px-4 py-6 sm:px-0">
          <div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 flex justify-between items-center	">
                  <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-3 w-1/6">
                    <div class="pt-2 relative mx-auto text-gray-600">
                      <input class="bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none w-full" [(ngModel)]='searchTerm'
                        type="search" name="search" placeholder="Buscar por nombre...">
                      <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
                        <svg class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                          viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                          width="512px" height="512px">
                          <path
                            d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="select_page">
                    <label for="pageSize" class="whitespace-nowrap text-sm text-gray-500 mr-2">Número de startups por página</label>
                    <select id="pageSize" (change)="changePagesize($event.target.value)" style="width:60px" class="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                         <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" (click)="changeOrder()">
                            ID
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Año
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Nombre
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Solicitud inicial
                          </th> 
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Entrevista
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Formulario técnico
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Informe ex ante
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Comité de evaluación
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Otorgado
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Fecha concesión
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr (click)="openStartup(startup.id)" class="cursor-pointer" *ngFor="let startup of startupsFilter|slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {{ startup.id }}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {{ startup.fecha_sol_inicial | date: 'yyyy'}}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <div class="text-sm font-medium text-gray-900">
                              {{ startup.nombre }}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.denegada_sol_inicial != 1 && startup.aplazada_sol_inicial != 1">
                              Aprobada
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="!startup.aprobada_sol_inicial && startup.aprobada_sol_inicial != 0  && startup.denegada_sol_inicial != 1 && startup.aplazada_sol_inicial != 1">
                              Por evaluar
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.denegada_sol_inicial == 1">
                              Denegada
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-700 text-white" *ngIf="startup.aplazada_sol_inicial == 1">
                              Aplazada
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 0">
                              No procede
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && !startup.fecha_entrevista">
                              Por concertar
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && !startup.participantes_startup_entrevista">
                              Agendada
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && !startup.denegada_entrevista && !startup.aplazada_entrevista">
                              Celebrada
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.denegada_entrevista == 1 && !startup.aplazada_entrevista">
                              Denegado
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && !startup.denegada_entrevista && startup.aplazada_entrevista == 1">
                              Aplazado
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 0">
                              No procede
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && !startup.formulario_enviado && startup.aplazada_entrevista != 1">
                              Por enviar enlace
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.formulario_enviado && !startup.tecnico && startup.aplazada_entrevista != 1">
                              Por cumplimentar
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico">
                              Cumplimentado
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 0">
                              No procede
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && !startup.exante">
                              Por realizar
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && !startup.concesion_exante && !startup.denegar_concesion_exante && !startup.aplazar_concesion_exante">
                              Realizado
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.concesion_exante == 1">
                              Favorable
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.denegar_concesion_exante == 1">
                              Desfavorable
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.aplazar_concesion_exante == 1">
                              Aplazada
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 0">
                              No procede
                            </span>
                            
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && !startup.fecha_comite">
                              Por evaluar 
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.fecha_comite">
                              Evaluada
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-black text-white" *ngIf="startup.aprobada_sol_inicial == 0">
                              No procede
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.otorgado == 1">
                              Si
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.otorgado == 0 && startup.aceptacion_exante == 0">
                              No
                            </span>
                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-700 text-white" *ngIf="startup.aprobada_sol_inicial == 1 && startup.fecha_entrevista && startup.participantes_startup_entrevista && startup.tecnico && startup.exante && startup.aceptacion_exante == 2 && startup.otorgado == 0">
                              Aplazada
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500" *ngIf="startup.fecha_concesion">
                            <span *ngIf="startup.otorgado == 1">{{ startup.fecha_concesion | date: 'dd/MM/yyyy'}} <br></span>
                            <span *ngIf="startup.otorgado == 1">{{ startup.fecha_validez | date: 'dd/MM/yyyy'}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /End replace -->
        <div class="paginacion overflow-auto">
          <button (click)="pagePrev()" *ngIf="page > 1" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 float-left">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z" clip-rule="evenodd" />
            </svg>
            Anterior
          </button>
          <button (click)="pageNext()" *ngIf="page <= maxPage" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 float-right">
            Siguiente
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </main>
    <header class="bg-white shadow" *ngIf="idStartup">
      <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
        <h1 class=" flex justify-between items-center">
          <span class="text-3xl font-bold text-gray-900">{{ startup?.nombre }}</span>
          <img *ngIf="startup?.logo" src="{{ startup?.logo }}" class="ml-2" style="max-height:35px" >
          <a class="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded inline-flex items-center ml-2" *ngIf="startup?.paginaweb" href="{{ startup?.paginaweb }}" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clip-rule="evenodd" />
            </svg>
            <span>Página web</span>
          </a>
        </h1>

        <a class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" href="https://api.gocircular.es/api/startups/generate_pdf/{{ startup?.id}}" target="_blank">
          <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
          <span>Ver PDF</span>
        </a>
      </div>
    </header>
    <main *ngIf="idStartup">
      <div >
        <div class="flex mt-5">
          <div class="w-1/3 text-center px-6 cursor-pointer tab_item" (click)="changeTab(1)" [ngClass]="{'tab_selected': tab == 1}">
            <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
              <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step text-xl font-bold">
               1
              </div>
              <div class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 class="font-bold text-sm">Solicitud Inicial</h2>
              </div>
            </div>
          </div>
          <div class="flex-1 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z"/></svg>
          </div>
          <div class="w-1/3 text-center px-6 cursor-pointer tab_item" (click)="changeTab(2)" [ngClass]="{'tab_selected': tab == 2}">
            <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
              <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step text-xl font-bold">
               2
              </div>
              <div class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 class="font-bold text-sm">Entrevista</h2>
              </div>
            </div>
          </div>
          <div class="flex-1 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z"/></svg>
          </div>
          <div class="w-1/3 text-center px-6 cursor-pointer tab_item" (click)="changeTab(3)" [ngClass]="{'tab_selected': tab == 3}">
            <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
              <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step text-xl font-bold">
               3
              </div>
              <div class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 class="font-bold text-sm">Formulario técnico</h2>
              </div>
            </div>
          </div>
          <div class="flex-1 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z"/></svg>
          </div>
          <div class="w-1/3 text-center px-6 cursor-pointer tab_item" (click)="changeTab(4)" [ngClass]="{'tab_selected': tab == 4}">
            <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
              <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step text-xl font-bold">
               4
              </div>
              <div class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 class="font-bold text-sm">Informe ex ante</h2>
              </div>
            </div>
          </div>
          <div class="flex-1 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 2h-7.229l7.014 7h-13.785v6h13.785l-7.014 7h7.229l10-10z"/></svg>
          </div>
          <div class="w-1/3 text-center px-6 cursor-pointer tab_item" (click)="changeTab(5)" [ngClass]="{'tab_selected': tab == 5}">
            <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
              <div class="w-1/3 bg-transparent h-20 flex items-center justify-center icon-step text-xl font-bold">
               5
              </div>
              <div class="w-2/3 bg-gray-200 h-24 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                <h2 class="font-bold text-sm">Comité de evaluación</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8" >
        <div class="mt-10 sm:mt-0" *ngIf="tab == 1">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0 mb-5 mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Información de la Startup</h3>
              <p class="mt-1 text-sm text-gray-600">
                Campos informativos provenientes del Radar
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <form (submit)="editStep1(form1.value)" [formGroup]="form1">
              <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-gray-50 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-2">
                      <label for="nombre" class="block text-sm font-medium text-gray-700">Nombre de la empresa</label>
                      <input type="text" disabled formControlName="nombre" id="nombre" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="fecha" class="block text-sm font-medium text-gray-700">Fecha de la solicitud</label>
                      <input type="date" formControlName="fecha_sol_inicial" id="fecha" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="cadenavalor" class="block text-sm font-medium text-gray-700">Cadena de Valor</label>
                      <input type="text" formControlName="cadenavalor" id="cadenavalor" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="etapacadena" class="block text-sm font-medium text-gray-700">Etapa en la cadena</label>
                      <input type="text" formControlName="etapacadena" id="etapacadena" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="principalesambitos" class="block text-sm font-medium text-gray-700">Ambito principal de actividad</label>
                      <input type="text" formControlName="principalesambitos" id="principalesambitos" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="poblacion" class="block text-sm font-medium text-gray-700">Población</label>
                      <input type="text" formControlName="poblacion" id="poblacion" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="paginaweb" class="block text-sm font-medium text-gray-700">Página Web</label>
                      <input type="text" formControlName="paginaweb" id="paginaweb" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="anoconstitucion" class="block text-sm font-medium text-gray-700">Año de constitución</label>
                      <input type="text" formControlName="anoconstitucion" id="anoconstitucion" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="nombrecontacto" class="block text-sm font-medium text-gray-700">Persona de contacto</label>
                      <input type="text" formControlName="nombrecontacto" id="nombrecontacto" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="telefonocontacto" class="block text-sm font-medium text-gray-700">Teléfono de contacto</label>
                      <input type="text" formControlName="telefonocontacto" id="telefonocontacto" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="emailcontacto" class="block text-sm font-medium text-gray-700">Email de contacto</label>
                      <input type="text" formControlName="emailcontacto" id="emailcontacto" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-12">
                      <label for="actividadprincipal" class="block text-sm font-medium text-gray-700">Actividad principal</label>
                      <textarea id="actividadprincipal" formControlName="actividadprincipal" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="actuaciones" class="block text-sm font-medium text-gray-700">Actuaciones especificas</label>
                      <textarea id="actuaciones" formControlName="actuaciones" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="equipo" class="block text-sm font-medium text-gray-700">Equipo</label>
                      <textarea id="equipo" formControlName="equipo" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="logros" class="block text-sm font-medium text-gray-700">Logros</label>
                      <textarea id="logros" formControlName="logros" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="aprobada" formControlName="aprobada_sol_inicial"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="aprobada" class="font-medium text-gray-700">¿Aprobada?</label>
                          <p class="text-gray-500">Si marcas esta casilla, la startup pasará a la siguiente etapa del proceso</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="denegada" formControlName="denegada_sol_inicial"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="denegada" class="font-medium text-gray-700">¿Denegada?</label>
                          <p class="text-gray-500">Si marcas esta casilla, la startup no pasará a la siguiente etapa del proceso</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="aplazada" formControlName="aplazada_sol_inicial"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="aplazada" class="font-medium text-gray-700">¿Aplazada?</label>
                          <p class="text-gray-500">Si marcas esta casilla, la startup no pasará a la siguiente etapa del proceso aunque no se descarta que en un futuro se pueda celebrar una entrevista</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-12">
                      <label for="comentarios" class="block text-sm font-medium text-gray-700">Comentarios</label>
                      <textarea id="comentarios" formControlName="comentario_sol_inicial"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                  <button type="submit" [disabled]="form1.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-10 sm:mt-0" *ngIf="tab == 2">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0 mb-5 mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Entrevista</h3>
              <p class="mt-1 text-sm text-gray-600">
                Campos informativos referentes a la entrevista
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <form (submit)="editStep2(form2.value)" [formGroup]="form2">
              <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-gray-50 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-2">
                      <label for="fecha_entrevista" class="block text-sm font-medium text-gray-700">Fecha de la entrevista</label>
                      <input type="date" formControlName="fecha_entrevista" id="fecha_entrevista" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2 lg:col-span-2">
                      <label for="hora_entrevista" class="block text-sm font-medium text-gray-700">Hora de la entrevista</label>
                      <input type="time" formControlName="hora_entrevista" id="fecha" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-12">
                      <label for="participantes_startup_entrevista" class="block text-sm font-medium text-gray-700">Participantes por parte de la startup</label>
                      <textarea id="participantes_startup_entrevista" formControlName="participantes_startup_entrevista"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="participantes_eco_entrevista" class="block text-sm font-medium text-gray-700">Participantes por parte de Ecoembes</label>
                      <textarea id="participantes_eco_entrevista" formControlName="participantes_eco_entrevista"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="comentario_entrevista" class="block text-sm font-medium text-gray-700">Comentarios y valoraciones de la entrevista</label>
                      <textarea id="comentario_entrevista" formControlName="comentario_entrevista"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="imgnu" class="block text-sm font-medium text-gray-700">Adjunta un documento</label>
                      <input #file type="file" class="custom-file-input" id="imgnu" (change)="preview(file.files)" />
                      <a href="{{ global_url + startup.documento_entrevista }}" *ngIf="startup.documento_entrevista" target="_blank" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">Ver documento</a>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="denegada_entrevista" formControlName="denegada_entrevista"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="denegada_entrevista" class="font-medium text-gray-700">¿Denegado?</label>
                          <p class="text-gray-500">Durante la entrevista se ha identificado algo que hace inviable otorgar el sello y se decide por Ecoembes y por la misma empresa no continuar con el proceso de evaluación</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="aplazada_entrevista" formControlName="aplazada_entrevista"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="aplazada_entrevista" class="font-medium text-gray-700">¿Aplazado?</label>
                          <p class="text-gray-500">Durante la entrevista se ha identificado algo que hace inviable otorgar el sello de forma temporal, por tanto, se decide por la startup y por Ecoembes aplazar la valoración del Comité de Evaluación</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                  <button type="submit" [disabled]="form2.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-10 sm:mt-0"  *ngIf="tab == 3">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0 mb-5 mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Formulario técnico</h3>
              <p class="mt-1 text-sm text-gray-600">
                Campos informativos referentes al formulario técnico
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2" *ngIf="!startup.formulario_enviado">
            <form (submit)="editStep3(form3.value)" [formGroup]="form3">
              <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-gray-50 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label for="fecha" class="block text-sm font-medium text-gray-700">Formulario técnico</label>
                      <a [routerLink]="[ '/formulario_tecnico/', startup_code ]" target="_blank" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">Ver formulario</a>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="aprobada" formControlName="formulario_enviado"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="aprobada" class="font-medium text-gray-700">¿Enviado?</label>
                          <p class="text-gray-500">Si marcas esta casilla, constará que has enviado el formulario técnico a la startup</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                  <button type="submit" [disabled]="form3.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2" *ngIf="startup.formulario_enviado">
            <form (submit)="editFormularioTecnico(formTecnico.value)" [formGroup]="formTecnico">
              <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-gray-50 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-12">
                      <label for="actuaciones" class="block text-sm font-medium text-gray-700">Actuaciones especificas</label>
                      <textarea id="actuaciones" formControlName="actuaciones" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="equipo" class="block text-sm font-medium text-gray-700">Equipo</label>
                      <textarea id="equipo" formControlName="equipo" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="logros" class="block text-sm font-medium text-gray-700">Logros</label>
                      <textarea id="logros" formControlName="logros" disabled class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="innovacion" class="block text-sm font-medium text-gray-700">Innovación</label>
                      <textarea id="innovacion" formControlName="innovacion" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="estrategia_crecimiento" class="block text-sm font-medium text-gray-700">Estrategia de crecimiento</label>
                      <textarea id="estrategia_crecimiento" formControlName="estrategia_crecimiento"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-12">
                      <label for="comentarios" class="block text-sm font-medium text-gray-700">Más información aportada por la startup</label>
                      <textarea id="comentarios" formControlName="comentarios"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6 lg:col-span-2" *ngIf="startup.tecnico?.documento">
                      <label for="fecha" class="block text-sm font-medium text-gray-700">Documento anexo</label>
                      <a href="{{ global_url + startup.tecnico?.documento }}" *ngIf="startup.tecnico?.documento" target="_blank" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">Ver documento</a>
                    </div>
                    <div class="col-span-12" *ngIf="startup.tecnico?.documento">
                      <label for="comentarios_documento" class="block text-sm font-medium text-gray-700">Anexos - Comentarios por parte de TheCircularLab</label>
                      <textarea id="comentarios_documento" formControlName="comentarios_documento"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                   
                    
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                  <button type="submit" [disabled]="formTecnico.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-10 sm:mt-0"  *ngIf="tab == 4">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0 mb-5 mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Informe ex ante</h3>
              <p class="mt-1 text-sm text-gray-600">
                Campos informativos referentes al informe ex ante
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <form (submit)="editStep4(form4.value)" [formGroup]="form4">
              <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-gray-50 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-2">
                      <label for="persona_exante" class="block text-sm font-medium text-gray-700">Persona/s que cumplimenta el informe</label>
                      <input type="text" formControlName="persona_exante" id="persona_exante" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="fecha_exante" class="block text-sm font-medium text-gray-700">Fecha del informe</label>
                      <input type="date" formControlName="fecha_exante" id="fecha_exante" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="sintesis" class="block text-sm font-medium text-gray-700">Síntesis de la descripción de la startup</label>
                      <textarea id="sintesis" formControlName="sintesis"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label for="fortalezas" class="block text-sm font-medium text-gray-700">Elementos positivos de la startup</label>
                      <textarea id="fortalezas" formControlName="fortalezas"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label for="debilidades" class="block text-sm font-medium text-gray-700">Elementos negativos o debilidades</label>
                      <textarea id="debilidades" formControlName="debilidades"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <!--
                    <div class="col-span-6 sm:col-span-6">
                      <label for="potencial" class="block text-sm font-medium text-gray-700">Potencial de la startup para generar un impacto positivo en el ámbito de la economía circular</label>
                      <textarea id="potencial" formControlName="potencial"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="viabilidad" class="block text-sm font-medium text-gray-700">Viabilidad y escalabilidad de su modelo de negocio; aceptación por el mercado y potencial de crecimiento</label>
                      <textarea id="viabilidad" formControlName="viabilidad"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="grado_innovacion" class="block text-sm font-medium text-gray-700">Grado de innovación de la startup</label>
                      <textarea id="grado_innovacion" formControlName="grado_innovacion"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="capacitacion" class="block text-sm font-medium text-gray-700">Capacitación y compromiso de su equipo de trabajo</label>
                      <textarea id="capacitacion" formControlName="capacitacion"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="logros_obtenidos" class="block text-sm font-medium text-gray-700">Logros obtenidos</label>
                      <textarea id="logros_obtenidos" formControlName="logros_obtenidos"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="premios" class="block text-sm font-medium text-gray-700">Logros obtenidos - Obtención de premios y reconocimientos por terceras entidades (ej: PYME innovadora)</label>
                      <textarea id="premios" formControlName="premios"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="aceleracion" class="block text-sm font-medium text-gray-700">Logros obtenidos - Completar de forma exitosa programas de aceleración exigentes</label>
                      <textarea id="aceleracion" formControlName="aceleracion"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="capitalriesgo" class="block text-sm font-medium text-gray-700">Logros obtenidos - Objtención de sumas relevantes en programas de capital riesgo exigentes</label>
                      <textarea id="capitalriesgo" formControlName="capitalriesgo"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="programas_id" class="block text-sm font-medium text-gray-700">Logros obtenidos - Participación en programas de ID regionales, nacionales y/o europeos.</label>
                      <textarea id="programas_id" formControlName="programas_id"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="contratos_relevantes" class="block text-sm font-medium text-gray-700">Logros obtenidos - Referencias de contratos relevantes con clientes. Si se conoce, aquellos firmados con empresas adheridas a Ecoembes.</label>
                      <textarea id="contratos_relevantes" formControlName="contratos_relevantes"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="contratos_servicios" class="block text-sm font-medium text-gray-700">Logros obtenidos - Contratos de servicios ya ejecutados con Ecoembes de forma exitosa</label>
                      <textarea id="contratos_servicios" formControlName="contratos_servicios"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="certificados" class="block text-sm font-medium text-gray-700">Logros obtenidos - Adquisición de certificados de calidad y aquellos que puedan estar relacionados con el ámbito de la economía circular.</label>
                      <textarea id="certificados" formControlName="certificados"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="otros" class="block text-sm font-medium text-gray-700">Logros obtenidos - Otros</label>
                      <textarea id="otros" formControlName="otros"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                  -->
                    <div class="col-span-6 sm:col-span-6">
                      <label for="valoracion_global" class="block text-sm font-medium text-gray-700">Valoración global de la startup</label>
                      <textarea id="valoracion_global" formControlName="valoracion_global"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="concesion_exante" formControlName="concesion_exante"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="concesion_exante" class="font-medium text-gray-700">¿Se propone concesión del distintivo?</label>
                          <p class="text-gray-500">Si marcas esta casilla, constará que se propone la concesión del distintivo</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="denegar_concesion_exante" formControlName="denegar_concesion_exante"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="denegar_concesion_exante" class="font-medium text-gray-700">¿Se propone denegar la concesión del distintivo?</label>
                          <p class="text-gray-500">Si marcas esta casilla, constará que se propone denegar la concesión del distintivo</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                      <div class="flex items-start">
                        <div class="flex items-center h-5">
                          <input id="aplazar_concesion_exante" formControlName="aplazar_concesion_exante"  type="checkbox" class="focus:ring-green-500 h-4 w-4 text-green-600 border border-gray-300 rounded">
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="aplazar_concesion_exante" class="font-medium text-gray-700">¿Se propone aplazar la concesión del distintivo?</label>
                          <p class="text-gray-500">Si marcas esta casilla, constará que se propone denegar la concesión del distintivo pero por un motivo transitorio; por lo que la startup puede solicitar el sello de nuevo cuando su situación cambie.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                  <button type="submit" [disabled]="form4.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-10 sm:mt-0"  *ngIf="tab == 5">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0 mb-5 mt-5">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Comité de evaluación</h3>
              <p class="mt-1 text-sm text-gray-600">
                Campos referentes al Comité de evaluación
              </p>
            </div>
          </div>
          <div class="mt-5 md:mt-0 md:col-span-2">
            <form (submit)="editStep5(form5.value)" [formGroup]="form5">
              <div class="shadow overflow-hidden sm:rounded-md">
                <div class="px-4 py-5 bg-gray-50 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-2">
                      <label for="fecha_comite" class="block text-sm font-medium text-gray-700">Fecha de la reunión del comité</label>
                      <input type="date" formControlName="fecha_comite" id="fecha_comite" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2">
                      <label for="otorgado" class="block text-sm font-medium text-gray-700">Decisión sobre el distintivo</label>
                      <select id="otorgado" formControlName="otorgado" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                        <option value="">Selecciona una opción</option>
                        <option value="0">Se decide no otorgar el distintivo</option>
                       <option value="1">Se decide otorgar el distintivo</option>
                      </select>
                    </div>
                    <div class="col-span-6 sm:col-span-2" *ngIf="form5.value.otorgado == 1">
                      <label for="fecha_concesion" class="block text-sm font-medium text-gray-700">Fecha de la comunicación de concesión</label>
                      <input type="date" formControlName="fecha_concesion" id="fecha_concesion" class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-span-6 sm:col-span-2" *ngIf="form5.value.otorgado && form5.value.otorgado == 0">
                      <label for="aceptacion_exante" class="block text-sm font-medium text-gray-700">Información a transmitir a la startup</label>
                      <select id="aceptacion_exante" formControlName="aceptacion_exante" autocomplete="country" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                        <option value="">Selecciona una opción</option>
                        <option value="0">No se otorga por no cumplir las bases del distintivo</option>
                       <!-- <option value="1">Se otorga el distintivo goCircular Pass</option> -->
                        <option value="2">No se otorga pero no se descarta en un futuro según su evolución</option>
                      </select>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="comentarios_exante" class="block text-sm font-medium text-gray-700">Comentarios sobre el Formulario técnico</label>
                      <textarea id="comentarios_exante" formControlName="comentarios_exante"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                    <div class="col-span-6 sm:col-span-6">
                      <label for="valoracion_startup_comite" class="block text-sm font-medium text-gray-700">Valoración general sobre la startup</label>
                      <textarea id="valoracion_startup_comite" formControlName="valoracion_startup_comite"  class="mt-1 focus:ring-green-500 focus:border-green-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                    </div>
                  </div>
                </div>
                <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                  <button type="submit" [disabled]="form5.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
  