import { Routes } from '@angular/router';
import { NotfoundComponent } from './authentication/404/not-found.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { FormularioComponent } from './formulario/formulario.component';
import { LoginComponent } from './login/login.component';

export const Approutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
        { path: '', redirectTo: '/startups', pathMatch: 'full' },
        {
          path: 'startups',
          component: HomeComponent
        },
        {
          path: 'startups/:id',
            component: HomeComponent,
        },
        
    ]
  }, 
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'formulario_tecnico/:id',
      component: FormularioComponent,
  },
  { path: '**', component: NotfoundComponent},
];