import { Component, OnInit } from '@angular/core';
import { ApicallsService } from '../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

import * as global from '../services/global.service';
import { AuthenticationService } from '../services/authentication.service';
import * as moment from 'moment';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  startups: any;
  startupsFilter:any;
  modalImportar = false;
  modalFilter = false;
  idStartup:any;
  exante:any;
  tecnico:any;
  page = 1;
  pageSize = 10;
  maxPage = 0;
  collectionSize = 0;
  form1!: FormGroup;
  form2!: FormGroup;
  tab = 1;
  form3!: FormGroup;
  formTecnico!: FormGroup;
  importForm!: FormGroup;
  filterForm!: FormGroup;
  form4!: FormGroup;
  form5!: FormGroup;
  startup:any;
  message!: string;
  nameFile: any = 'Ningún archivo seleccionado';
  imagePath: any;
  startups_import: any = [];
  menu_open = false;
  startup_code: any;
  imgURL: any;
  global_url: any;
  order: string = 'desc';
  _searchTerm = '';
  constructor(private formBuilder: FormBuilder,  private titleService: Title, private auth: AuthenticationService, private toastr: ToastrService, private api: ApicallsService,private currentroute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.global_url = global.API_URL;
    this.form1 = this.formBuilder.group({
      id: ['', Validators.required ],
      nombre: ['', Validators.required ],
      fecha_sol_inicial: ['', Validators.required ],
      cadenavalor: [''],
      etapacadena: [''],
      principalesambitos: [''],
      poblacion: [''],
      paginaweb: [''],
      nombrecontacto: [''],
      telefonocontacto: [''],
      emailcontacto: [''],
      anoconstitucion: [''],
      actividadprincipal: [''],
      aprobada_sol_inicial: [''],
      denegada_sol_inicial: [''],
      aplazada_sol_inicial: [''],
      actuaciones: [''],
      equipo: [''],
      logros: [''],
      comentario_sol_inicial: [''],
    });

    this.form2 = this.formBuilder.group({
      id: ['', Validators.required ],
      fecha_entrevista: ['', Validators.required ],
      hora_entrevista: ['', Validators.required ],
      comentario_entrevista: [''],
      participantes_startup_entrevista: [''],
      participantes_eco_entrevista: ['' ],
      documento_entrevista: [''],
      denegada_entrevista: [''],
      aplazada_entrevista: [''],
      change_doc: [0]
    });

    this.form3 = this.formBuilder.group({
      id: ['', Validators.required ],
      formulario_enviado: [''],
    });
    this.importForm = this.formBuilder.group({
      startup: ['', Validators.required ],
    });
    this.filterForm = this.formBuilder.group({
      
      filter_ano: [''],
      filter_sol_inicial: [''],
      filter_entrevista: [''],
      filter_form_tecnico: [''],
      filter_ex_ante: [''],
      filter_comite: [''],
      filter_otorgado: [''],
    });

    this.formTecnico = this.formBuilder.group({
      innovacion: [''],
      estrategia_crecimiento: [''],
      documento: [''],
      comentarios_documento: [''],
      comentarios: [''],
      actuaciones: [''],
      equipo: [''],
      logros: [''],
    });
  
    this.form4 = this.formBuilder.group({
      id: ['', Validators.required ],
      persona_exante: ['', Validators.required ],
      fecha_exante: ['', Validators.required ],
      concesion_exante: [''],
      denegar_concesion_exante: [''],
      aplazar_concesion_exante: [''],
      sintesis: [''],
      fortalezas: [''],
      debilidades: [''],
      potencial: [''],
      viabilidad: [''],
      grado_innovacion: [''],
      capacitacion: [''],
      logros_obtenidos: [''],
      premios: [''],
      aceleracion: [''],
      capitalriesgo: [''],
      programas_id: [''],
      contratos_relevantes: [''],
      contratos_servicios: [''],
      certificados: [''],
      otros: [''],
      valoracion_global: [''],
    });

    this.form5 = this.formBuilder.group({
      id: ['', Validators.required ],
      fecha_comite: [''],
      fecha_concesion: [''],
      otorgado: [''],
      aceptacion_exante: [''],
      comentarios_exante: [''],
      valoracion_startup_comite: [''],
    });

    this.traerStartups();
   
    this.currentroute.params.subscribe( params => {
      this.idStartup = +params['id'];
      if(this.idStartup){
        this.api.getStartup(this.idStartup).subscribe( ( data: any ) => {
          this.startup = data.data;
          this.setTitle(this.startup.nombre);
          console.log(this.startup);
          console.log(atob('c3RhcnR1cF9pZD0xMQ==').split('=')[1]);
          this.exante = data.data.exante;
          this.tecnico = data.data.tecnico;
          if(this.startup){
            this.tab = this.startup.step;
            this.startup_code = btoa('startup_id='+this.startup.id);
            this.form1.patchValue(data.data);
            this.form2.patchValue(data.data);
            this.form3.patchValue(data.data);
            this.formTecnico.reset();
            this.formTecnico.patchValue(data.data);
            if(this.tecnico){
              this.formTecnico.patchValue(this.tecnico);
            }
            this.form4.reset();
            this.form4.patchValue(data.data);
            if(this.exante){
              this.form4.patchValue(this.exante);
            }
            this.form5.patchValue(data.data);
          }
      });
      }
    
    });

    

  }

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(val: string) {
      this._searchTerm = val;
      this.startupsFilter = this.filter(val);
      this.page = 1;
  }
  filter(v: string) {
      return this.startups.filter((x: { nombre: string; }) => x.nombre.toLowerCase().indexOf(v.toLowerCase()) !== -1);
  }
  pagePrev(){
    this.page = this.page - 1;
  }
  pageNext(){
    this.page = this.page + 1;
    console.log(this.collectionSize);
    
  }
  traerStartups(){
    this.api.getStartups().subscribe( ( data: any ) => {
        this.startups = data.response;
        this.startups.forEach(element => {
          if(element.fecha_concesion){
            var date = moment(element.fecha_concesion).add(2, 'years');
            element.fecha_validez = date;
          }else{
            element.fecha_validez = null;
          }
          
        });
        this.startupsFilter = this.startups;
        this.collectionSize = this.startupsFilter.length;
        this.maxPage = this.collectionSize / this.pageSize;

        if(this.order == 'desc'){
          this.startupsFilter.sort((a, b) => b.id - a.id);
        }else{
          this.startupsFilter.sort((b, a) => b.id - a.id);
        }

        this.traerStartupsImport();
    });
  }
  traerStartupsImport(){

    this.api.getStartupsImport().subscribe( ( data: any ) => {
      for (const [key, value] of Object.entries(data)) {
        var find = this.startups.find((x: { radar_id: any; }) => x.radar_id == key);
        if(!find){
          this.startups_import[key] = value;
        }
      }
  });
  }
  openStartup(startup){
    const url = this.router.createUrlTree(['/startups/', startup])
    window.open(url.toString(), '_blank')
  }

  preview(files: string | any[]) {
    if (files.length === 0) {
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.nameFile = this.imagePath[0].name;
      this.form2.patchValue({'documento_entrevista': this.imgURL});
      this.form2.patchValue({'change_doc':1});
    };
  }

  editStep1(formValue: any) {
    console.log(formValue);
    this.api.updateStartup1(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
        
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
  editStep2(formValue: any) {
    console.log(formValue);
    this.api.updateStartup2(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
        
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
  editStep3(formValue: any) {
    console.log(formValue);
    this.api.updateStartup3(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
        
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
  editFormularioTecnico(formValue: any) {
    console.log(formValue);
    this.api.updateFormularioTecnico(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
        
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
  editStep4(formValue: any) {
    console.log(formValue);
    this.api.updateStartup4(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
        
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
  editStep5(formValue: any) {
    console.log(formValue);
    this.api.updateStartup5(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
        
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
  changeTab(tab){
    this.tab = tab;
  }
  logout() {
    this.auth.logout();
  }
  openMenu(){
    this.menu_open = !this.menu_open;
  }
  openModalImportar(){
    this.modalImportar = true;
  }
  cerrarModalImportar(){
    this.modalImportar = false;
  }
  openModalFilter(){
    this.modalFilter = true;
  }
  cerrarModalFilter(){
    this.modalFilter = false;
  }
  checkOtorgado(startup){
    console.log(this.startup);
    
    
  }
  importar(formValue){
    var id = formValue.startup;
    this.api.importStartup(id).subscribe( data => {
      this.toastr.success('Startup importada correctamente', 'Actualizar startup');
      this.modalImportar = false;
      this.traerStartups();
    }, error => {
      this.toastr.error('No se ha podido importar la startup', 'Actualizar startup');
    }
  );
  }
  filtrar(formValue){
    console.log(formValue);
    var filter = this.startups;
  
    if(formValue.filter_ano != ''){
      filter = filter.filter(function(x:any) {
        return x.fecha_sol_inicial.toLowerCase().indexOf(formValue.filter_ano.toLowerCase()) !== -1;
      });
    }
    if(formValue.filter_sol_inicial != ''){
      if(formValue.filter_sol_inicial == 'aprobada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.denegada_sol_inicial != 1 && x.aplazada_sol_inicial != 1;
        });
      }
      if(formValue.filter_sol_inicial == 'por_evaluar'){
        filter = filter.filter(function(x:any) {
          return !x.aprobada_sol_inicial && x.aprobada_sol_inicial != 0  && x.denegada_sol_inicial != 1 && x.aplazada_sol_inicial != 1;
        });
      }
      if(formValue.filter_sol_inicial == 'aplazada'){
        filter = filter.filter(function(x:any) {
          return x.aplazada_sol_inicial == 1;
        });
      }
      if(formValue.filter_sol_inicial == 'denegada'){
        filter = filter.filter(function(x:any) {
          return x.denegada_sol_inicial == 1;
        });
      }
     
    }
    if(formValue.filter_entrevista != ''){
      if(formValue.filter_entrevista == 'no_procede'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 0;
        });
      }
      if(formValue.filter_entrevista == 'pendiente'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && !x.fecha_entrevista
        });
      }
      if(formValue.filter_entrevista == 'agendada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && !x.participantes_startup_entrevista;
        });
      }
      if(formValue.filter_entrevista == 'celebrada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && !x.denegada_entrevista && !x.aplazada_entrevista;
        });
      }
      if(formValue.filter_entrevista == 'aplazada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && !x.denegada_entrevista && x.aplazada_entrevista == 1;
        });
      }
      if(formValue.filter_entrevista == 'denegada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.denegada_entrevista == 1 && !x.aplazada_entrevista;
        });
      }
    }
    if(formValue.filter_form_tecnico != ''){
      if(formValue.filter_form_tecnico == 'no_procede'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 0;
        });
      }
      if(formValue.filter_form_tecnico == 'por_enviar'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && !x.formulario_enviado && x.aplazada_entrevista != 1;
        });
      }
      if(formValue.filter_form_tecnico == 'por_cumplimentar'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.formulario_enviado && !x.tecnico && x.aplazada_entrevista != 1;
        });
      }
      if(formValue.filter_form_tecnico == 'cumplimentado'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico;
        });
      }
    }
    if(formValue.filter_ex_ante != ''){
      if(formValue.filter_ex_ante == 'no_procede'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 0;
        });
      }
      if(formValue.filter_ex_ante == 'por_realizar'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && !x.exante;
        });
      }
      if(formValue.filter_ex_ante == 'realizado'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && !x.concesion_exante && !x.denegar_concesion_exante && !x.aplazar_concesion_exante;
        });
      }
      if(formValue.filter_ex_ante == 'favorable'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.concesion_exante == 1;
        });
      }
      if(formValue.filter_ex_ante == 'desfavorable'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.denegar_concesion_exante == 1;
        });
      }
      if(formValue.filter_ex_ante == 'aplazada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.aplazar_concesion_exante == 1;
        });
      }
    }
    if(formValue.filter_comite != ''){
      if(formValue.filter_comite == 'no_procede'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 0;
        });
      }
      if(formValue.filter_comite == 'por_evaluar'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && !x.fecha_comite;
        });
      }
      if(formValue.filter_comite == 'evaluada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.fecha_comite;
        });
      }
    }
    if(formValue.filter_otorgado != ''){
      if(formValue.filter_otorgado == 'no_procede'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 0;
        });
      }
      if(formValue.filter_otorgado == 'si'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.otorgado == 1;
        });
      }
      if(formValue.filter_otorgado == 'no'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.otorgado == 0 && x.aceptacion_exante == 0;
        });
      }
      if(formValue.filter_otorgado == 'aplazada'){
        filter = filter.filter(function(x:any) {
          return x.aprobada_sol_inicial == 1 && x.fecha_entrevista && x.participantes_startup_entrevista && x.tecnico && x.exante && x.aceptacion_exante == 2 && x.otorgado == 0;
        });
      }
    }
    
    this.startupsFilter= filter;
    this.page = 1;
    this.modalFilter = false;
  }
  setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle + ' - Intranet PASS');
  }
  changePagesize(page){
    this.pageSize = page;
  }
  changeOrder(){
    if(this.order == 'desc'){
      this.order = 'asc';
      this.startupsFilter.sort((b, a) => b.id - a.id);
    }else{
      this.order = 'desc';
      this.startupsFilter.sort((a, b) => b.id - a.id);
    }
  }
}
