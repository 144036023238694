
<div id="layout-wrapper">
 
   <app-navigation></app-navigation>

    
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">

                <router-outlet></router-outlet>
                
            </div> 
        </div>

        
       
    </div>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <script>document.write(new Date().getFullYear())</script> © Inkoru.
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right d-none d-sm-block">
                        Todos los derechos reservados.
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>