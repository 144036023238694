import { Component, OnInit } from '@angular/core';
import { ApicallsService } from '../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import * as global from '../services/global.service';


@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {

  startups: any;
  idStartup:any;
  exante:any;
  tecnico:any;
  formTecnico!: FormGroup;
  startup:any;
  message!: string;
  nameFile: any = 'Ningún archivo seleccionado';
  imagePath: any;
  imgURL: any;

  constructor(private formBuilder: FormBuilder,  private toastr: ToastrService, private api: ApicallsService,private currentroute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {


    this.formTecnico = this.formBuilder.group({
      innovacion: ['', Validators.required ],
      estrategia_crecimiento: ['', Validators.required ],
      documento: [''],
      comentarios_documento: [''],
      comentarios: [''],
      actuaciones: [''],
      equipo: [''],
      logros: [''],
      change_doc: [0]
    });


    
    this.currentroute.params.subscribe( params => {
      this.idStartup = params['id'];
      this.idStartup = atob(this.idStartup).split('=')[1];
      if(this.idStartup){
        this.api.getStartup(this.idStartup).subscribe( ( data: any ) => {
          this.startup = data.data;
          this.tecnico = data.data.tecnico;
          if(this.startup){
            this.formTecnico.patchValue(data.data);
            this.formTecnico.patchValue(this.tecnico);
            this.formTecnico.patchValue({'startup_id': this.idStartup});
          }
      });
      }
    
    });

    

  }

  preview(files: string | any[]) {
    if (files.length === 0) {
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      this.nameFile = this.imagePath[0].name;
      this.formTecnico.patchValue({'documento': this.imgURL});
      this.formTecnico.patchValue({'change_doc': 1});
    };
  }

  
  editFormularioTecnico(formValue: any) {
    console.log(formValue);
    this.api.updateFormularioTecnico(formValue, this.idStartup).subscribe( data => {
        this.toastr.success('Startup actualizada correctamente', 'Actualizar startup');
      }, error => {
        this.toastr.error('No se ha podido actualizar la startup', 'Actualizar starup');
      }
    );
  }
}
