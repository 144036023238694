import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Intranet  - goCircular Pass';
  constructor() {

  }
  ngOnInit() {
    console.log(location.hostname);
    if (location.hostname != 'localhost' && location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
    
  }
}
