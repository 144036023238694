<!-- This example requires Tailwind CSS v2.0+ -->
<div>
  <header class="bg-white shadow" *ngIf="idStartup">
    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold text-gray-900">
        {{ startup?.nombre }} - Formulario técnico
      </h1>
    </div>
  </header>
  <main *ngIf="idStartup && !startup?.tecnico">
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="mt-10 sm:mt-0">
        <div class="mt-5 md:mt-0 md:col-span-2">
          <form (submit)="editFormularioTecnico(formTecnico.value)" [formGroup]="formTecnico">
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-gray-50 sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-12">
                    <label for="innovacion" class="block text-sm font-medium text-gray-700">Innovación</label>
                    <p class="block text-sm font-small text-gray-500 mb-5">Comenta los elementos más innovadores de vuestra startup ya sea mediante el desarrollo de tecnologías, productos o servicios innovadores, o bien mediante la innovación en vuestro modelo de negocio.</p>
                    <textarea id="innovacion" formControlName="innovacion" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                  <div class="col-span-12">
                    <label for="estrategia_crecimiento" class="block text-sm font-medium text-gray-700">Estrategia de crecimiento</label>
                    <p class="block text-sm font-small text-gray-500 mb-5">Identifica cuáles son vuestros principales retos y describe vuestra estrategia de expansión.</p> 
                    <textarea id="estrategia_crecimiento" formControlName="estrategia_crecimiento"  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                  <div class="col-span-12">
                    <label for="comentarios_documento" class="block text-sm font-medium text-gray-700">Anexos</label>
                    <p class="block text-sm font-small text-gray-500 mb-5">Puedes adjuntar cualquier documento o imagen que consideres que pueda se rde utilidad para la valoración por parte del Comité de Evaluación. Si decides adjuntar algún archivo, resume a continuación su contenido.</p> 
                    <textarea id="comentarios_documento" formControlName="comentarios_documento"  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                  <div class="col-span-12">
                    <label for="comentarios" class="block text-sm font-medium text-gray-700">Más información por parte de la startup</label>
                    <p class="block text-sm font-small text-gray-500 mb-5">En este apartado podéis ampliar cualquier información de la solicitud inicial o aportar cualquier novedad, detalle o matiz que consideréis de utilidad.</p> 
                    <textarea id="comentarios" formControlName="comentarios"  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                  <div class="col-span-12">
                    <label for="imgnu" class="block text-sm font-medium text-gray-700">Adjunta un documento (Si quiere adjuntar más de uno, adjunta un archivo zip)</label>
                    <input #file type="file" class="custom-file-input" id="imgnu" (change)="preview(file.files)" />
                  </div>
                 
                  <div class="col-span-12">
                    <label for="comentarios" class="block text-sm font-medium text-gray-700">Información adicional</label>
                    <p class="block text-sm font-small text-gray-500 mb-5">A continuación se muestra la información que aportó vuestra startup en su solicitud inicial para conseguir el distintivo goCircular Pass</p> 
                  </div>
                  <div class="col-span-12">
                    <label for="actuaciones" class="block text-sm font-medium text-gray-700">Actuaciones especificas</label>
                    <textarea id="actuaciones" formControlName="actuaciones" disabled class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                  <div class="col-span-12">
                    <label for="equipo" class="block text-sm font-medium text-gray-700">Equipo</label>
                    <textarea id="equipo" formControlName="equipo" disabled class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                  <div class="col-span-12">
                    <label for="logros" class="block text-sm font-medium text-gray-700">Logros</label>
                    <textarea id="logros" formControlName="logros" disabled class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" ></textarea>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-100 text-right sm:px-6">
                <button type="submit" [disabled]="formTecnico.invalid" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
  <main *ngIf="startup?.tecnico">
    <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="mt-10 sm:mt-0">
        <div class="mt-5 md:mt-0 md:col-span-2">
          Ya has enviado el formulario técnico.
        </div>
      </div>
    </div>
  </main>
</div>
  