<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a href="index.html" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/logo.svg" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-dark.png" alt="" height="17">
                    </span>
                </a>

                <a href="index.html" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/logo-light.svg" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/logo-light.png" alt="" height="25">
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" data-toggle="collapse" data-target="#topnav-menu-content">
                <i class="fa fa-fw fa-bars"></i>
            </button>

        </div>

        <div class="d-flex">

            <div class="dropdown d-inline-block d-lg-none ml-2">
                <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-magnify"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                    aria-labelledby="page-header-search-dropdown">
        
                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" class="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                    <i class="bx bx-fullscreen"></i>
                </button>
            </div>

            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-bell bx-tada"></i>
                    <span class="badge badge-danger badge-pill">4</span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                    aria-labelledby="page-header-notifications-dropdown">
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0" key="t-notifications"> Notificaciones </h6>
                            </div>
                            <div class="col-auto">
                                <a href="notificaciones.html" class="small" key="t-view-all"> Ver todas</a>
                            </div>
                        </div>
                    </div>
                    <div data-simplebar style="max-height: 230px;">
                        <a href="" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-info rounded-circle">
                                        <i class="bx bxs-hourglass-bottom font-size-19"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1" key="t-shipped">Cita finalizada</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">La cita #ID acaba de terminar. ¿Ha ido todo bien?</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">Hace 52 seg</span></p>
                                    </div>
                                    <div class="mt-3">
                                        <button type="button" class="btn btn-outline-success btn-sm waves-effect waves-light"><i class="mdi mdi-thumb-up"></i></button>
                                        <button type="button" class="btn btn-outline-danger btn-sm waves-effect waves-light" data-toggle="modal" data-target=".modal-finalizar-cita"><i class="mdi mdi-thumb-down"></i></button>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-warning rounded-circle font-size-16">
                                        <i class="bx bx-error font-size-19"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1" key="t-your-order">Faltan datos del artista</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Es necesario añadir el código de Higiénico Sanitario de Nombre Artista</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">Hace 3 min</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="../single-appointment-pre-proyecto-completada.html" class="text-reset notification-item">
                            <div class="media">
                                <img src="assets/images/users/avatar-2.jpg"
                                    class="mr-3 rounded-circle avatar-xs" alt="user-pic">
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">Jordi Martinez</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-simplified">Ha escrito una nota en la cita #8172.</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">Hace 2 horas</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-info rounded-circle font-size-16">
                                        <i class="bx bxs-face font-size-19"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1" key="t-shipped">Abonar al artista</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-grammer">Hay que pagar XX,XX€ a Nombre del Artista por 3 trabajos realizados</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">Hace 15 min</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="" class="text-reset notification-item">
                            <div class="media">
                                <div class="avatar-xs mr-3">
                                    <span class="avatar-title bg-danger rounded-circle font-size-16">
                                        <i class="bx bx bx-error font-size-19"></i>
                                    </span>
                                </div>
                                <div class="media-body">
                                    <h6 class="mt-0 mb-1">Menor de edad</h6>
                                    <div class="font-size-12 text-muted">
                                        <p class="mb-1" key="t-occidental">Nombre de Cliente es menor de edad y no tiene un tutor legal vinculado.</p>
                                        <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">Hace 1 hora</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="p-2 border-top">
                        <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
                            <i class="mdi mdi-arrow-right-circle mr-1"></i> <span key="t-view-more">Ver más...</span> 
                        </a>
                    </div>
                </div>
            </div>

            <div class="dropdown d-inline-block">
                <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img class="rounded-circle header-profile-user" src="assets/images/users/{{ user!.avatar }}"
                        alt="{{ user!.name }}">
                    <span class="d-none d-xl-inline-block ml-1" key="t-henry">{{ user!.name }}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <!-- item-->
                    <a class="dropdown-item d-block" href="configuracion.html"><i class="bx bx-user font-size-16 align-middle mr-1"></i>  <span key="t-settings">Configuración</span></a>
                    <a class="dropdown-item" href="pagina-sesion-pausa.html"><i class="bx bx-lock-open font-size-16 align-middle mr-1"></i> <span key="t-lock-screen">Bloquear</span></a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" (click)="logout()"><i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> <span key="t-logout">Cerrar sesión</span></a>
                </div>
            </div>

        </div>
    </div>
</header>

<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

            <div class="collapse navbar-collapse" id="topnav-menu-content">
                <ul class="navbar-nav">

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-dashboard" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx bx-store mr-2"></i><span key="t-dashboards">Estudios</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/estudios/list/']" class="dropdown-item" key="t-informacion">Todos los estudios</a>
                            <a [routerLink]="['/estudios/new/']" class="dropdown-item" key="t-informacion">Nuevo estudio</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-uielement" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="dripicons-user-id mr-2"></i>
                            <span key="t-ui-elements"> Managers</span> 
                            <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/managers/list/']" class="dropdown-item" key="t-informacion">Todos los managers</a>
                            <a [routerLink]="['/managers/new/']" class="dropdown-item" key="t-informacion">Nuevo manager</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-pages" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="dripicons-user mr-2"></i><span key="t-apps">Artistas</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/artistas/list/']" class="dropdown-item" key="t-informacion">Todos los artistas</a>
                            <a [routerLink]="['/artistas/new/']" class="dropdown-item" key="t-informacion">Nuevo artista</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-components" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="dripicons-user-group mr-2"></i><span key="t-components">Clientes</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/clientes/list/']" class="dropdown-item" key="t-informacion">Todos los clientes</a>
                            <a [routerLink]="['/clientes/new/']" class="dropdown-item" key="t-informacion">Nuevo cliente</a>
                        </div>
                    </li>
        

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-more" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bx-calendar mr-2"></i><span key="t-extra-pages">Agenda</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a [routerLink]="['/trabajos/calendario/']" class="dropdown-item" key="t-calendario">Calendario</a>
                            <a [routerLink]="['/trabajos/new/']" class="dropdown-item" key="t-nuevo-trabajo">Nuevo trabajo</a>
                            <a [routerLink]="['/trabajos/list/']" class="dropdown-item" key="t-todos-los-trabajos">Todos los trabajos</a>
                            <div class="dropdown">
                                <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-email"
                                    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span key="t-email">Todos los casos</span> <div class="arrow-down"></div>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="topnav-email">
                                    <div class="dropdown">
                                        <a [routerLink]="['/trabajos/list/2/0']" class="dropdown-item" key="t-todos-los-trabajos">Walk-in</a>
                                    </div>
                                    <div class="dropdown">
                                        <a [routerLink]="['/trabajos/list/1/1']" class="dropdown-item" key="t-todos-los-trabajos">Citas Pre-proyecto</a>
                                    </div>
                                    <div class="dropdown">
                                        <a [routerLink]="['/trabajos/list/1/2']" class="dropdown-item" key="t-todos-los-trabajos">Citas Boceto</a>
                                    </div>
                                    <div class="dropdown">
                                        <a [routerLink]="['/trabajos/list/1/3']" class="dropdown-item" key="t-todos-los-trabajos">Citas Tatuaje</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-layout" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="bx bxs-user-voice mr-2"></i><span key="t-layouts">Comunicación</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-dashboard">
                            <a href="comunicaciones.html" class="dropdown-item" key="t-email">Comunicaciones</a>
                            <a href="notificaciones.html" class="dropdown-item" key="t-notificaciones">Notificaciones</a>
                        </div>
                    </li>

                </ul>
            </div>
        </nav>
    </div>
</div>