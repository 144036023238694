import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as global from './global.service';
import { InjectorInstance } from '../app.module';
import { AuthenticationService } from './authentication.service';

@Injectable({providedIn: 'root'})
export class ApicallsService {

  constructor(public http: HttpClient) {

  }

  getStartups() {
    return this.http.get(global.API_URL + '/api/startups');
  }
  getStartupsImport() {
    return this.http.get('https://radar.thecircularlab.com/wp-json/empresas/v1/pass_send');
  }
  importStartup(id: string) {
    return this.http.get(global.API_URL + '/api/startups/import/' + id);
  }
  getStartup(id: string) {
    return this.http.get(global.API_URL + '/api/startups/view/' + id);
  }
  
  updateStartup1(startup: any, id: string) {
    return this.http.post( global.API_URL + '/api/startups/update_1/' + id, startup);
  }
  updateStartup2(startup: any, id: string) {
    return this.http.post( global.API_URL + '/api/startups/update_2/' + id, startup);
  }
  updateStartup3(startup: any, id: string) {
    return this.http.post( global.API_URL + '/api/startups/update_3/' + id, startup);
  }
  updateStartup4(startup: any, id: string) {
    return this.http.post( global.API_URL + '/api/startups/update_4/' + id, startup);
  }
  updateStartup5(startup: any, id: string) {
    return this.http.post( global.API_URL + '/api/startups/update_5/' + id, startup);
  }
  updateFormularioTecnico(startup: any, id: string) {
    return this.http.post( global.API_URL + '/api/startups/update_tecnico/' + id, startup);
  }

}